<template>
	<main class="main" id="main" v-bind:class="theme">
		<div class="contility">
			<img v-bind:src="contility" alt="the contility" title="The contility" />
		</div>
		<!-- end contility -->
		<div class="flex-container">
			<!-- GET SIDEBAR -->
			<div class="sidebar__wrapper" id="sidebarWrapper">
				<Sidebar @toggleSidebar="toggleSidebar"></Sidebar>
			</div>
			<!-- end sidebar__wrapper -->
			<!-- MAIN CONTENT -->
			<div class="main-content">
				<div class="container">
					<div class="user-management">
						<h1>Edit User {{ `${getUserFirstName} ${getUserLastName}` }}</h1>
						<!-- GET USER FORM -->
						<AddUserForm></AddUserForm>
					</div>
					<!-- end container -->
				</div>
				<!-- end container -->
			</div>
			<!-- end main-content -->
		</div>
		<!-- end flex-container -->
	</main>
</template>

<script>
import Sidebar from "./../components/sidebar/Sidebar.vue"
import AddUserForm from "./../components/forms/AddUserForm.vue"
import {mapGetters} from "vuex"

export default {
	data() {
		return {
			theme: "dashboard user has-sidebar blue-bg",
			contility: require("./../assets/img/contility.svg"),
		}
	},
	components: {
		Sidebar,
		AddUserForm,
	},
	computed: {
		...mapGetters("user", ["getUserFirstName", "getUserLastName"]),
	},
    methods: {
        toggleSidebar() {
			this.$store.dispatch("views/toggleSidebar")
		},
    }
}
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/dashboard.scss";
</style>
